@import '../../variables.scss';

.loaderContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    @media (max-width: $media-desktop) {
        margin-top: 50px;
    }
}

.loader {
    height: 40px;
    width: 40px;
    animation: spin 1s linear infinite;
    border: 3px solid lightgray;
    border-top: 3px solid transparent;
    border-radius: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}