@import '../../variables.scss';

.alert {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 245px;
    background-color: white;
    box-shadow: 0px 10px 10px #0000001A;
    top: 10px;
    z-index: 9999;
    right: 0;

    .alertContent {
        height: 100%;
        width: 100%;
        position: relative;
        padding: 1.25rem;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        font-size: 0.75rem;
        font-family: $font-regular;
        color: var(--color-black);

        > svg {
            margin-right: 0.5rem;
            position: relative;
            top: -1px;
            height: 20px;
            width: 20px;
        }

        .alertClose {
            width: 17px;
            height: 17px;
            padding: 0;
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: white;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 200ms;

            svg {
                height: 17px;
                width: 17px;
            }

            &:hover {
                cursor: pointer;
                opacity: .75;
            }
        }
    }
}