/* media */
$media-mobile: 540px;
$media-tablet: 639px;
$media-desktop: 1024px;
$media-large: 1200px;
$media-xlarge: 1440px;

/* fonts */
$font-regular: 'Helvetica Neue LT', Arial, sans-serif;
$font-medium: 'Helvetica Neue LT Medium', Arial, sans-serif;
// $font-light: HelveticaNeueLight;
// $font-regular: HelveticaNeueRegular;
// $font-medium: HelveticaNeueMedium;
