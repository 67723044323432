@import '../../variables.scss';

.textContainer {
  // margin-top: 6rem;
  text-align: center;
  padding: 0;
  letter-spacing: 0;
}

.title {
  font-size: 50px;
  color: var(--color-black);
  margin: 10px 0;
  font-family: $font-regular;
  letter-spacing: 0;
  line-height: 1.2;
}

.text {
  color: var(--color-black);
  margin: 10px 0;
  font-family: $font-regular;
  letter-spacing: 0;
}

@media (max-width: $media-desktop) {
  .title {
    font-size: 32px;
  }
}