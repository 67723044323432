.sizePicker {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E1E1E1;
}

.sizePickerYears {
    gap: 20px;

    & > div {
        white-space: nowrap;
        width: auto;

        &:hover {
            translate: -1px 0;
            margin-right: -2px;
        }
    }
}

.sizePickerItem {
    padding: 0 .5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    box-sizing: border-box;
    border-radius: 9999px;
    border-width: 1px;
    border-color: white;
    border-style: solid;
    align-items: center;
    justify-content: center;
    font-size: .75rem;
    line-height: 1rem;
    color: #525252;
    cursor: pointer;
    text-transform: uppercase;
    transition: border-color .3s ease-in-out;
    &:hover {
        border-color: black;
    }
}

.sizePickerItemSelected {
    background-color: var(--color-black);
    color: white;
    box-sizing: border-box;
}

.sizePickerItemSelectedNoHover{
    &:hover {
        border-width: 1px;
        box-sizing: border-box;
        translate: 0!important;
        margin-right: 0!important;
    }
}

.sizePickerItemDisabled {
    opacity: .3;
    pointer-events: none;
    cursor: auto;
}