@import '../../variables.scss';

.container {
    margin: var(--item-margin);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 70px;

    &.noImage {
        padding: 0 170px;

        .productInfo {
            flex: 1;
            max-width: 100%;
        }

        .thumbs {
            gap: 40px;
        }

        .thumbWrapper {
            margin: 5px 0 30px;
        }
    }

    &.imgLeft {
        flex-direction: row;
    }

    &.imgRight {
        flex-direction: row-reverse;
    }
}

.productInfo {
    flex: 1;
    position: relative;
    z-index: 2;
}

.name {
    color: var(--color-black);
    margin-bottom: 25px;
    text-transform: capitalize;
    font-size: 56px;
    letter-spacing: 0;
    font-family: $font-regular;
}

.thumbs {
    display: flex;
}

.thumbWrapper {
    flex: 0 1 calc(100% / 3 - (var(--thumb-spacing) * 2));
    position: relative;
    margin: var(--thumb-spacing) ;


    
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &:hover{
        .thumbImageDefault {
            opacity: 0;
        }
        .thumbImageAlt {
            opacity: 1;
        }
    }

    &:first-child {
        margin-left: 0;
    }
}

.thumbImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: all 0.45s ease-in-out;
}

.collectionPrice {
    position: absolute;
    bottom: -30px;
}

.thumbImageDefault {
    opacity: 1;
}

.thumbImageAlt {
    opacity: 0;
}

.info {
    display: flex;
    padding-top: 25px;
    font-size: 17px;
    letter-spacing: 0;
    font-family: $font-regular;
}

.price,
.priceCrossed {
    box-sizing: border-box;
    color: var(--color-grey);
}

.priceCrossed {
    margin-left: calc(var(--thumb-spacing) * 1.5);
    text-decoration: line-through;
}

.price {
    color: var(--color-red);
}

.fullPrice {
    color: var(--color-black);
}

.discount {
    margin-left: calc(var(--thumb-spacing) * 2);
    color: var(--color-red);
    text-transform: capitalize;
}

.cta {
    align-self: center;
    border-radius: 30px;
    background-color: var(--color-black);
    font-size: 18px;
    color: white;
    font-family: $font-medium;
    text-decoration: none;
    margin: 25px 0;
    transition-duration: .2s;
    width: auto;
    outline: none;
    padding: 16px 20px 14px;
    display: inline-block;
    margin-top: 10px;

    &:hover,
    &:focus {
        color: white;
        opacity: .8;
    }

    &Secondary {
        background-color: white;
        color: var(--color-black);
        border: 1px solid var(--color-black);

        &:hover,
        &:focus {
            background-color: var(--color-black);
            color: white;
            opacity: 1;
        }
    }
}

.imageSection {
    flex: 1;
    position: relative;
}

.bundleGroup {
    align-items: flex-start;
    margin: 200px 0;

    &:last-child {
        margin-bottom: 100px;
    }

    .noImage {
        padding: 0;
        margin: 50px 0;

        &:last-child {
            margin-bottom: 0;
        }

        .productInfo {
            padding: 0;
        }

        .name {
            font-size: 20px;
            letter-spacing: 0;
            font-family: $font-regular;
            margin-bottom: 15px;
            text-transform: none;
        }

        .thumbs {
            gap: 10px;
        }
    }

    .productInfo {
        margin-left: auto;
        max-width: 50%;
        flex: auto;
        background-color: white;
        padding: 50px;
    }

    .imageSection {
        width: 950px;
    }

    .promoImage {
        width: 100%;
        display: block;
    }

    &.imgRight,
    &.imgLandscape {
        .productInfo {
            margin-right: auto;
            margin-left: 0;
        }
    }

    &.imgLandscape {
        margin-top: 600px;
        margin-bottom: 64px;

        // &:first-child {
        //     margin-top: 700px;
        // }

        > .productInfo {
            display: flex;
            margin: 0;
            max-width: none;
            background: none;
            padding: 0;
            align-items: flex-start;
            justify-content: flex-start;
            min-height: 100%;

            &:after {
                content: '';
                background-color: white;
                height: calc(100% + 10px);
                width: 100%;
                position: absolute;
                z-index: 1;
                margin-top: 60px;
            }

            > div:first-child {
                flex: 1;
                background-color: white;
                padding: 50px;
                box-sizing: border-box;
                width: 50%;
                height: auto;
                z-index: 2;
            }

            > div:last-child {
                flex: 1;
                background-color: white;
                padding: 50px;
                margin-top: 80px;
                margin-left: auto;
                max-width: 44%;
                z-index: 2;
            }
        }

        .imageSection {
            left: auto;
            right: auto;
            height: 600px;
            //max-width: 1340px;

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }
}

.bundleGroupTag {
    box-shadow: 0px 10px 25px #0000001A;
    background-color: white;
    border-radius: 17px;
    font-size: 12px;
    font-family: $font-regular;
    border: 1px solid #CDCCCC;
    padding: 10px 12px 8px;
    display: inline-block;
    letter-spacing: 0;
    margin-bottom: 30px;
}

.bundleGroupTitle {
    letter-spacing: 0;
    font-family: $font-regular;
    font-size: 56px;
    text-transform: none;
}

.bundleGroupDesc {
    letter-spacing: 0;
    font-family: $font-regular;
    font-size: 14px;
    margin-top: 20px;
    max-width: 60%;
}

.desktop {
    display: grid;
    grid-template-columns: repeat(3, auto);
}

.mobile {
    display: none;
}

.hiddenLandscapeImage {
    display: none;
}

@media (max-width: $media-desktop) {
    .container {
        flex-direction: column-reverse;
        margin: 20px 20px 80px;
        gap: 35px;

        &.noImage {
            padding: 0;

            .thumbs {
                gap: 8px;
            }
        }

        &:first-child {
            margin-top: 0 !important;
        }

        &.imgLeft,
        &.imgRight,
        &.imgLandscape {
            flex-direction: column;
        }
        &.imgRight {
            .imageSection {
                width: calc(100% + 20px);
                margin-right: -20px;
                > img {
                    display: block;
                    width: 100%;
                }
            }
        }
        &.imgLeft {
            .imageSection {
                width: calc(100% + 20px);
                left: -20px;
                > img {
                    display: block;
                    width: 100%;
                }
            }
        }


        .productInfo {
            width: 100%;
        }

        &.imgLandscape {
            margin-top: 20px;
            margin-bottom: 50px;

            .imageSection {
                width: calc(100% + 40px) !important;
                left: -20px;
                height: auto;
                max-width: none;
                margin-right: 0;
            }

            .productInfo {
                flex-direction: column;

                > div:first-child {
                    position: static !important;
                    height: auto !important;
                    width: auto !important;
                    padding: 0;
                }

                > div:last-child {
                    margin: 0;
                    padding: 0;
                    max-width: none;
                    width: 100%;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .imageSection {
        width: 80%;
        margin-right: -20px;
    }

    .name {
        font-size: 30px;
        margin: 25px 0 15px;
    }

    .info {
        padding-top: 15px;
        font-size: 14px;
    }

    .thumbWrapper {
        flex: 0 1 calc((100% / 3) - 5px);
    }

    .cta {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .ctaSecondary {
        margin-bottom: 40px;
    }

    .bundleGroup {
        &:last-child {
            margin-bottom: 0;
        }

        .imageSection {
            width: 100%;
            position: relative !important;
            top: auto !important;
        }

        &.imgLeft .imageSection,
        &.imgRight .imageSection,
        &.imgLandscape .imageSection {
            width: calc(100% + 20px);
        }

        &.imgLeft .imageSection {
            left: -20px;
            overflow-x: hidden;
        }

        .productInfo {
            max-width: 100%;
            padding: 0;
        }

        .bundleGroupTitle {
            font-size: 30px;
        }

        .bundleGroupDesc {
            max-width: 100%;
            margin-bottom: 30px;
            padding: 0;
        }

        .bundleGroupTag {
            margin-bottom: 20px;
        }
    }
}
