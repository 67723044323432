.colorPicker {
    display: flex;
    flex-wrap: wrap;
}

.colorPickerButton {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.25rem;
    outline: 2px solid #0000;
    outline-offset: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: none;
    background-color: transparent;
}

.colorPickerCircle {
    border-radius: 9999px;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: none;
    transition: box-shadow 0.3s ease-in-out;

    span {
        position: absolute;
        border-radius: 9999px;
        inset: 0.25rem;
    }

    &:hover,
    &:focus {
        cursor: pointer;
        box-shadow: inset 0 0 0 1px var(--color-black);
    }

    &.active {
        box-shadow: inset 0 0 0 1px var(--color-black);
        pointer-events: none;
    }
}

.colorPickerItemHidden {
    display: none;
}