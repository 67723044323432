.overlay{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container{
    width: 95%;
    max-width: 1350px;
    margin-top: 5vh;
    height: 100vh;
    background-color: white;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 40px;
    overflow: scroll;
    .close{
        position: absolute;
        margin:0;
        top: 15px;
        right: 15px;
        background-color: transparent;
        padding:0;
        height: 20px;
        width: 20px;
        &:hover{
            opacity: 0.75;
        }
        svg{
            height: 20px;
            width: 20px;
        }
    }
}