@import '../../variables.scss';

.loaderContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    margin-top: 150px;
    padding: 0 170px;
    width: 100%;
}

.fadeIn { opacity: 1; }
.fadeOut { opacity: 0; }

.loaderHeader,
.loaderBox,
.loaderFooter,
.loaderButton {
    background-color: #eaeaea;
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.1) 100%);
        animation: loading 1.5s infinite;
    }
}

.loaderHeader {
    width: 70%;
    height: 60px;
}

.loaderBody {
    display: flex;
    gap: 15px;
    margin-top: 20px;

    .loaderBox {
        width: 33%;
        height: 320px;
    }
}

.loaderFooter {
    width: 20%;
    height: 20px;
    margin-top: 10px;
}

.loaderButton {
    width: 160px;
    height: 60px;
    border-radius: 30px;
}

@keyframes loading {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@media (max-width: $media-desktop) {
    .loaderContainer {
        margin-top: 20px;
        padding: 0 20px;
    }

    .loaderHeader {
        height: 20px;
        width: 50%;
    }

    .loaderBody {
        margin-top: 5px;
        gap: 5px;

        .loaderBox {
            height: 115px;
        }
    }

    .loaderFooter {
        margin-top: 15px;
        height: 15px;
        width: 50%;
    }

    .loaderButton {
        height: 45px;
        border-radius: 0;
        width: 65%;
    }
}