@import '../../variables.scss';

.container {
    width: 100%;
    padding: 0 20px;
    height: var(--header-height);
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.cta {
    display: flex;
    font-family: $font-regular;
    font-size: .875rem;
    font-weight: 400;
    margin-left: 10px;
    color: var(--color-black);

    span {
        margin-left: 10px;
    }

    svg {
        height: 15px;
    }
}

@media (max-width: $media-desktop) {
    .cta {
        margin-left: 0;
    }
}