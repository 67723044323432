@import 'variables';
.s7container[mode=normal] {
    height: 65vh !important;

    .viewer-wrapper>* {
        height: 50% !important;
    }

    canvas {
        top: 0 !important;
        height: 58vh !important;
        object-fit: contain !important;
    }

    .s7panleftbutton,
    .s7panrightbutton {
        top: 45% !important;
    }
    .s7panleftbutton {
        left: 50px!important;
    }

    .s7panrightbutton{
        right: 50px!important;
    }
    .alt-image-bottom .viewer-container,
    .alt-image-bottom .vfdp-s7-viewer-preload-container {
        padding-bottom: 0 !important;
    }

    .viewer-wrapper .alt-image-bottom {
        height: 80vh !important;
    }

    .viewer-container {
        height: 80% !important;
    }

    .s7swatches>div {
        top: 40px
    }

    .s7swatches #leftButton_9,
    .s7swatches #rightButton_A {
        top: 95px;
    }

    .s7swatches {
        top: 25vh !important;
    }

    .s7controlbar.ZoomView {
        width: 85%;
        margin: 0 auto;
        left: 6%;
        bottom: -15px;
    }
    
}

.s7container[mode=fullscreen] {
    .s7container[mode=normal] {
        height: 80vh !important;
    }
    .s7panleftbutton {
        left: 0px!important;
    }

    .s7panrightbutton{
        right: 0px!important;
    }
}


@media (min-width: $media-xlarge) {
    .s7container[mode=normal] {
        .s7container .s7zoomview {
            margin-top: 10px;
        }
    }
    .s7container[mode=fullscreen] {
        .s7container .s7zoomview{
            margin-top: 50px;
        }
    }
}