@import '../../variables.scss';

.dropdown {
    position: relative;
    height: 40px;
}
  
.dropdownButton {
  border-radius: 1.5rem;
  border: 1px solid #9A9A9A;
  font-size: .875rem;
  line-height: 1.125rem;
  padding: 0 1rem;
  color: var(--color-black);
  background-color: transparent;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-black);
    background-color: transparent;
    border-color: var(--color-black);
    cursor: pointer;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .caret {
    margin-left: .5rem;
    position: relative;
  }

  select {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0;
  }
}