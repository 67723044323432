@import '../../variables.scss';

.label {
    font-family: $font-medium;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.item {
    border-top: 1px solid #E1E1E1;
    padding-top: 1rem;
}

.itemCollapsed {
    & .info {
        max-height: 1000px;
        margin-bottom: 1rem;
    }

    & .header {
        svg {
            rotate: 180deg;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1rem;
    cursor: pointer;

    svg {
        transition-duration: .2s;
        width: 15px;
    }
}

.info {
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    transition-duration: .25s;

    h3 {
        font-size: .875rem;
        margin-bottom: 5px;
        margin-top: 0;
        font-family: $font-regular;
        font-weight: 600;
    }

    p {
        font-size: .875rem;
        line-height: 1.125rem;
    }
}

.benefitContainer {
    display: flex;
    margin-bottom: 20px;

    img {
        max-width: 3.25rem;
        min-width: 3.25rem;
        object-fit: contain;
        margin-right: 1rem;
    }

    p {
        margin: 0;
    }

}

.techContainer {
    margin: 0 0 20px 0;

    h3 {
        font-family: "Neue Helvetica VFDP", helvetica, sans-serif;
        text-transform: none;
        margin-bottom: 5px;
    }
}

.useWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    padding: 20px 0
}

.useContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 100px;
    padding: 5px 0;
    text-align: center;
    h3 {
        margin-top: 15px;
        text-transform: none;
        font-weight: 400;
    }
}

.featureWrapper {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    margin:0;
    padding-bottom: 20px;
    list-style-type: square;
    li{
        margin-bottom: 10px;
        font-size: 14px;
        margin-left: 1.25rem;
    }
}

@media (max-width: $media-tablet) {
    .featureWrapper {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
    
}
