.container{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 250;
    background-color: rgba(0,0,0,.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    opacity: 1;
    transition: 250ms
}

.contentHidden{
    opacity: 0;
}

.wrapper{
    width: 65vw;
    height: 90vh;
    background-color: white;
    position: relative
}

.closeCta{
    position: absolute;
    top: 0;
    right:0;
    padding: 10px;
    color: white;
    border-radius: 0;
    margin: 0;
    background-color: transparent !important;
    z-index: 105;
    svg{
        height: 20px;
        width: 20px;
    }
    &:hover {
        opacity: .5;
    }
}

.loader{
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    background-color: white;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div{
        margin-top: 0;
    }
}