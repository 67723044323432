.inseamPicker {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E1E1E1;
}

.inseamPickerItem {
    width: auto;
    text-transform: capitalize;
    padding: 0 1rem;
    border: none;
}