@font-face {
    font-family: 'Helvetica Neue LT';
    src: url('../assets/fonts/HelveticaNeueLT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Helvetica Neue LT Medium';
    src: url('../assets/fonts/HelveticaNeueLTMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}