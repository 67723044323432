@import '../variables';

.wrapper {
    width: 100%;
    display: flex;
    margin-top: var(--detail-wrapper-margin-top)
}

.loadingOverlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: $media-tablet) {
    .wrapper {
        flex-direction: column;
    }
}

@media (max-width: $media-desktop) {
    .wrapper {
        margin-top: 1rem;
    }
}