@import '../../variables.scss';

.container {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    transition: all .2s ease-in-out, opacity .01s linear;;
    padding-bottom: 64px;

    &.activeFilters {
        margin-top: 0;
    }

    &.hidden {
        opacity: 0;
    }
}

.showMoreContainer {
    display: flex;
    justify-content: center;
}

.showMore {
    border-radius: 30px;
    background-color: var(--color-black);
    font-size: 18px;
    color: white;
    font-family: $font-medium;
    text-decoration: none;
    margin: 25px auto;
    transition-duration: .2s;
    width: auto;
    outline: none;
    padding: 16px 20px 14px;
    box-sizing: border-box;
    display: inline-block;

    &:hover,
    &:focus {
        color: white;
        opacity: .8;
    }
}

@media (max-width: $media-desktop) {
    .showMore {
        margin: 0 auto;
        font-size: 16px;
        max-width: none;
    }
}