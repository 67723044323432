@import '../../variables.scss';

.container {
    width: 100%;
    background-color: rgba(42, 42, 42, .95);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    padding: 1.75rem 0;
    max-height: 200px;
    transition: max-height 0.35s ease-out;


    &.minimized {
        max-height: 100px;

        .minimize {
            display: none;
        }

        .expand {
            display: block;
        }

        .title {
            visibility: hidden;
            color: transparent;
            height: 0;
            margin: 0;
            transition: all 0.35s ease-out;
        }

        .itemContainer {
            display: none;
            flex: 0 0 1px;
        }

        .thumbWrapper {
            opacity: 0;
        }

        .priceContainer {
            flex: 1;
            flex-direction: row;
            padding-left: 10%;
            margin-right: 70px;
            align-items: center;
            justify-content: end;
        }
        
        .cta,
        .priceWrapper,
        .priceContainer {
            margin-top: 0;
            margin-bottom: 0;
        }

        .cta {
            margin: 0;
            align-self: center;
        }

        .price {
            padding-left: 0;
        }

        .priceWrapper {
            margin-right: 25px;
            display: none;
        }

        .discount,
        .priceCrossed,
        .price {
            text-transform: uppercase;
            display: block;
            font-size: 1.5rem;
            margin-right: 25px;
        }

        .priceBlock {
            flex-direction: row;
        }
        .textContainer{
            margin-top: 0;
            justify-content: center;
        }
        
        .titleMinimized,
        .subtitleMinimized {
            visibility: visible;
            color: white;
            height: auto;
        }
    }

    &.collection {
        max-height: 140px;
        padding: 10px 0;
    }
}

.expand {
    display: none;
    width: 30px;
    height: 30px;
}

.title, .titleMinimized {
    font-size: 1.6rem;
    font-family: $font-medium;
    margin-right: 1.6rem;
    text-transform: none;
    color: white;
    margin-bottom: 2rem;
}

.subtitle, .subtitleMinimized {
    font-size: 1.4rem;
    margin-right: 1.6rem;
    font-family: $font-regular;
}

.titleMinimized,
.subtitleMinimized {
    visibility: hidden;
    color: transparent;
    height: 0;
    margin: 0;
    transition: all 0.35s ease-out;
}

.subtitleMinimized {
    font-size: 1rem;
    margin-top: 0.5rem;
}

.wrapper {
    display: flex;
}

.stepIndicator {
    font-size: 1rem;
    font-weight: 400;
}

.textContainer {
    padding: 0 10px;
    flex: 0 0 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bundleName {
    color: white;
    font-family: $font-regular;
    display: block;
    font-weight: 400;
    font-size: 1.5rem;
}

.status {
    color: white;
    display: block;
    font-weight: 300;
    font-size: 1.1rem;
    font-family: $font-regular;
    margin-bottom: 0.8rem;
}

.itemContainer {
    color: white;
    flex: 0 0 33.33%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top: 5px;

}

.mySwiper {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    width: 290px;
    min-height: 120px;

    :global(.swiper-pagination-bullet) {
        border: solid 1px #fff; 
        background: var(--swiper-pagination-bullet-inactive-color, transparent);
        opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
    }
  
    :global(.swiper-pagination-bullet-active) {
        background-color: white;
    }

    :global(.swiper-pagination-bullets.swiper-pagination-horizontal) {
        bottom: var(--swiper-pagination-bottom, 0px);
    }

}

.swiperslide {

    width: 100%;
    height: 100%;
    text-align: center;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

}

.sliderButton {
    width: 10%;
    text-align: center;
}

.sliderArrow {
    position: relative; 
    width: auto;
    height: 50px;
    top: -15px;
    color:white;
    border: none;
    background-color: transparent;
}

.priceContainer {
    color: white;
    flex: 0 0 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    margin-right: 0;
}

.collection {
    .priceContainer {
        justify-content: flex-start;
    }
}

.discount {
    font-family: $font-medium;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.priceWrapper {
    display: flex;
    margin: 1rem 0 0;
}

.price,
.priceCrossed {
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 400;
    display: block;
    font-family: $font-regular;
}

.price {
    padding-left: 0.4rem;
    font-weight: 500;
}

.priceCrossed {
    padding-right: 0.4rem;
    text-decoration: line-through;
}

.priceBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 295px;
}

.alert {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 245px;
    background-color: white;
    box-shadow: 0px 10px 10px #0000001A;
    top: 10px;
    z-index: 9999;
    right: 0;

    .alertContent {
        height: 100%;
        width: 100%;
        position: relative;
        padding: 1.25rem;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        font-size: 0.75rem;
        font-family: $font-regular;
        color: var(--color-black);

        > svg {
            margin-right: 0.5rem;
            position: relative;
            top: -1px;
            height: 20px;
            width: 20px;
        }

        .alertClose {
            width: 17px;
            height: 17px;
            padding: 0;
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: white;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 200ms;

            svg {
                height: 17px;
                width: 17px;
            }

            &:hover {
                cursor: pointer;
                opacity: .75;
            }
        }
    }
}

.cta {
    align-self: stretch;
    background-color: white;
    font-weight: 400;
    color: var(--color-black);
    font-family: $font-medium;
    text-decoration: none;
    text-align: center;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    align-self: center;
    border-radius: 30px;
    border: none;
    font-size: 1.125rem;
    line-height: 1.5rem;
    width: auto;
    outline: none;
    padding: .75rem 2.25rem .5rem;

    &:hover,
    &:focus {
        opacity: .9;
        cursor: pointer;
    }
}

.disabled {
    opacity: .7;
    color: #999;
}

.thumbWrapper {
    //flex: 0 1 calc(100% / 3 - (var(--thumb-spacing) * 2));
    display: flex;
    //margin: var(--thumb-spacing);
    position: relative;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.25s ease-out;
    overflow: hidden;

    &.current {
        border: 2px solid white;
        pointer-events: none;
    }

    &:hover {
        border: 2px solid white;

        .thumbText {
            opacity: 1;
        }

        .thumbImage {
            opacity: .5;
        }
    }

    &.collection {
        max-height: 96px;
        max-width: 96px;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.thumbImage {
    opacity: .5;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    transition: 200ms;
}

.thumbText {
    opacity: 0;
    transition: all 0.35s ease-out;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
    position: absolute;
    top: 0;
    font-family: "Neue Helvetica VFDP Cond", helvetica, sans-serif;
    letter-spacing: .5px;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;

    span {
        display: block;
        width: 100%;
        text-align: center;
        letter-spacing: 0;
    }
}

.icons {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.thumbWrapperConfigured {
    .thumbImage {
        opacity: 1;
    }
}

.ctaContainer{
    display: flex;
    justify-content: center;
}


.tickIcon {
    background-color: transparent;
    height: 30px;
    left: 5px;
    opacity: 1;
    position: absolute;
    top: 5px;
    transition: background-color 0.2s ease-in-out;
    transition-delay: 0ms;
    width: 30px;
    z-index: 2;

    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background: #ffffff;
        transform: translate(7px, 13px) rotate(45deg);
        transform-origin: left;
        transition: all 150ms;
        transition-delay: 100ms;
    }

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background: #ffffff;
        transform: translate(13px, 18px) rotate(-45deg);
        transform-origin: left;
        transition: all 150ms;
        transition-delay: 0ms;
    }

}

.tickIconActive {
    background-color: #000000;
    opacity: 1;
    transition-duration: 0.1s;
    transition-delay: 200ms;
    border-radius: 9999px;

    &:before {
        width: 9px;
        transition-delay: 200ms;
    }

    &:after {
        width: 13px;
        transition-delay: 300ms;
    }
}

.wrapperHidden {
    opacity: 0;
}

.addToCartCompleteWrapper {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 1.5rem;
        font-family: $font-regular;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    svg {
        margin-bottom: 0.5rem;
    }
}




@media (max-width: $media-desktop) {
    .container,
    .container.minimized {
        padding: 1rem 0;
        max-height: unset;
        
        .wrapper {
            padding: 0;
        }

        .icons {
            display: none;
        }

        .itemContainer,
        .textContainer {
            display: none;
        }

        .priceContainer {
            margin-top: 0;
            margin-right: 0;
            flex: 1;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            align-items: center;
            // justify-content: space;

            justify-content: space-between;
            padding: 0 30px;
        }

        .price {
            padding-left: 0.4rem;
            margin: 0;
            font-size: 1rem;
        }

        .priceCrossed {
            padding-right: 0.4rem;
            margin: 0;
            font-size: 1rem;
        }

        .discount,
        .priceWrapper {
            flex: calc(50% - 12px);
            font-size: 1.5rem;
            margin: 0;
            display: flex !important;
        }

        .priceWrapper {
            margin-top: 0.25rem;
        }

        .discount {
            display: flex;
            justify-content: flex-start;
        }

        .cta {
            margin: 0;
            flex: 0 0 100%;
            display: flex;
            justify-content: center;
            max-width: 170px;
            font-size: 1rem;
        }

        .priceBlock {
            flex-direction: row;
        }

        .discount {
            margin-right: 10px;
        }
    }
    .container.collection {
        max-height: max-content;
        .wrapper {
            flex-wrap: wrap;
        }
        .thumbWrapper {
            max-height: 75px;
            max-width: 75px;
        }
        .bundleName {
            font-size: 16px;
            margin-bottom: 10px;
        }
        .thumbText,
        .status {
            display: none;
        }
        .priceBlock {
            margin-top: 10px;
        }
        .tickIcon {
            height: 50%;
            width: 50%;
            left: 25%;
            top: 25%;

            &:before {
                top: calc(50% - 13px);
                left: calc(50% - 13px);
            }
            &:after {
                top: calc(50% - 13px);
                left: calc(50% - 13px);
            }
        }
        .thumbWrapperConfigured:not(.current) {
            border: 2px solid white;
        }
        .itemContainer,
        .textContainer {
            display: flex;
            flex-basis: 100%;
            height: auto;
            align-self: flex-start;
            text-align: center;
            margin: 0;
            padding: 0;
        }
        .itemContainer {
            align-self: center;
        }
        .priceContainer {
            justify-content: center;
            .priceBlock {
                flex-direction: column;
                flex-basis: 100%;
            }
            .ctaContainer {
                .cta {
                    max-width: max-content;
                    padding-bottom: 8px;
                    padding-top: 12px;
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
        }
    }

    

    .sliderButton {
        width: 5%;
        text-align: center;
    }

    .mySwiper {
        width: 250px;
    }

}

@media (max-width: $media-tablet) {
    .priceContainer {
        flex-wrap: nowrap;
        justify-content: center!important;
        align-items: center!important;
        padding: 0 10px!important;
    }

    .collection {
        .priceContainer {
            .priceBlock {
                flex-basis: auto !important;
            }
        }
    }


    .cta {
        max-width: unset!important;
    }

    .sliderButton {
        width: 15%;
        text-align: center;
    }

    .mySwiper {
        width: 250px;
    }

    
    
}

@media (max-width: $media-mobile){
    .priceContainer {
        flex-direction: column !important;
        gap: 0px !important;;
    }

    .priceBlock {
        flex-direction: column !important;
        margin-top: 0px !important;
    }

    .discount {
        margin-right: 0px !important;
    }

    .sliderButton {
        width: 15%;
        text-align: center;
    }

    .mySwiper {
        width: 250px;
        margin-bottom: 0px;
        min-height: 115px;
        :global(.swiper-pagination-bullets.swiper-pagination-horizontal) {
            bottom: var(--swiper-pagination-bottom, 5px);
        }
    }

    
    
}