@import '../../variables.scss';

.container {
    display: none;
    width: 100%;
    position: sticky;
    top: 125px;
    z-index: 90;
}

.item {
    cursor: pointer;
    position: relative;
    padding: 0 20px;
    justify-content: center;
    height: 60px;
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    color: #515151;
    background-color: #E7E7E7;
    border-right: 2px solid #D6D6D6;
    font-family: "Neue Helvetica VFDP Cond", helvetica, sans-serif;

    .label {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 2px 0;
    }

    .size {
        font-size: 13px;
        font-weight: 400;
        margin: 2px 0;
    }

    .selectSize {
        font-size: 12px;
        font-weight: 400;
        margin: 2px 0;
        text-transform: capitalize;
    }

    .tick {
        background-color: transparent;
        height: 18px;
        width: 18px;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: background-color 0.2s ease-in-out;
        transition-delay: 0ms;
        z-index: 2;
    
        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            background: #ffffff;
            transform: translate(-4px, 7px) rotate(45deg);
            transform-origin: left;
            transition: all 150ms;
            transition-delay: 100ms;
        }
    
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            background: #ffffff;
            transform: translate(-1.4px, 11px) rotate(-45deg);
            transform-origin: left;
            transition: all 150ms;
            transition-delay: 0ms;
        }
    }

    &:last-of-type {
        border-right: none;
    }

    &.active {
        background-color: #F0F0F0;
        color: var(--color-black);
        pointer-events: none;
    }
    &.configured {
        .tick {
            background-color: #000000;
            opacity: 1;
            transition-duration: 0.1s;
            transition-delay: 200ms;
            border-radius: 9999px;
            top: 5px;
            left: 5px;
    
            &:before {
                width: 5px;
                transition-delay: 200ms;
            }
    
            &:after {
                width: 8px;
                transition-delay: 300ms;
            }
        }
    }
}

@media (max-width: $media-desktop) {
    .container{
        display: flex;
    }
    .containerScrolled {
        top: 47px;
    }
}


@media (max-width: $media-tablet) {
    .containerScrolled {
        top: 121px;
    }
}