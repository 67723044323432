@import '../../variables.scss';

.container {
    flex: 1 0 35%;
    font-family: $font-regular;
}

.wrapper {
    margin: 0 .76923rem 0 1rem;
}

.name {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
    font-weight: 600;
    font-family: $font-regular;
}

.label {
    font-size: .875rem;
    font-family: $font-medium;
}

.selectedDescription {
    font-size: .875rem;
    text-transform: capitalize;
}

.pickerContainer {
    margin: 1rem 0;
    position: relative;
}

.pickerContainerSize {
    border-top: 1px solid #E1E1E1;
    padding-top: 1rem;
}

.pickerText {
    margin-bottom: .5rem;
}

.sizeGuide {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E1E1E1;

    .label {
        margin-bottom: 1rem;
        display: block;
    }

    p {
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 20px;
    }
}

.sizeHelperCTA {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    font-size: .875rem;
    margin: 0;
    color: var(--color-black);
    border-radius: 0;
    transition: all .3s ease-in-out;
    text-decoration: underline;

    &:hover,
    &:focus {
        opacity: .9;
    }
}

.cta {
    margin: 0;
    width: 100%;
    padding: 1rem 1.5rem;
    color: white;
    background-color: var(--color-black);
    border: none;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: $font-medium;
    border-radius: 30px;
    transition: all .3s ease-in-out;
    margin-bottom: 1rem;
    text-align: center;

    &:hover,
    :focus {
        cursor: pointer;
        opacity: .9;
    }
}

.whiteCta {
    &:before {
        content: '';
        height: 1.5rem;
        width: 1.5rem;
        background-color: white;
        mask: url('../../../assets/svg/addToCart.svg') left center / contain no-repeat;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 5px;
    }
}

.removeCta {
    &:before {
        mask: url('../../../assets/svg/removeFromCart.svg') left center / contain no-repeat;
    }
}

// .ctaAddToCart {
//     background-color: var(--color-red);
//     color: white;

//     &:hover {
//         background-color: var(--color-black);
//         color: white;
//     }

//     &:focus {
//         background-color: var(--color-red);
//         color: white;
//     }
// }

.ctaDisabled {
    opacity: .4;
    cursor: default;

    // &:hover {
    //     background-color: var(--color-red);
    //     color: white;
    // }
}

.ctaOOS {
    background-color: #ccc;
    color: #fff;
    pointer-events: none;
}


.selectedStockAlert {
    color: #C22014;
    font-family: $font-medium;
    font-size: .875rem;
    letter-spacing: 0;
    margin-left: 10px;
}