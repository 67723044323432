@import 'variables';

body {
    font-family: $font-regular;
    overflow-x: hidden;
    position: relative;
    &.overlay {
        overflow: hidden;
    }
}

:root {
    /* palette */
    --color-black: #0f0f0f;
    --color-grey: #64696A;
    --color-red: #c22014;
    --color-lightgray: #e0e1df;

    /* listing */
    --list-margin: 2.5rem;

    /* bundle list */
    --item-margin: 64px 0;
    --thumb-spacing: 5px;

    /* bundle detail */
    --gallery-items-gap: 18px;
    --detail-wrapper-margin-top: 1.5rem;
    --header-height: 55px;
}

.b-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 72vh;
    margin-bottom: 50px;
}

.b-container-fill {
    align-self: stretch;
}

.b-container {
    width: 100%;
    max-width: 106.15385rem;
    padding: 0 20px;
    transition: opacity 300ms;
    box-sizing: border-box;

    &.b-container--hidden {
        opacity: 0;
    }
}

.customhtml2 .close-button {
    display: none;
}

@media (max-width: $media-desktop) {
    .b-container:not(.b-app-detail .b-container) {
        padding: 0;
    }
}

@media (max-width: $media-tablet) {
    .b-app-detail .b-container {
        padding: 0
    }
}

// Hide XLPR PASS
#full-container #container.maximized {
    display: none;
}

// Hide NL Popup
#full-container #container {
    display: none;
}

// Fullscreen Gallery

.b-app #quickview-box.pdp-swatches #product-content-container {
    display: none;
}

.b-app #quickview-box.pdp-swatches #product-views-container{
    background-color: #fff;
    float: initial;
    margin: 0 auto;
    width: 52.08333%;
}

