@import '../../variables.scss';

.wrapper {
    position: relative;
    margin-top: 20px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E2E2E2;
}

.container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}
  
.filters {
    display: flex;
    flex-direction: row;
    gap: .75rem;
    flex-wrap: wrap;
}

.filter {
    height: 40px;
    position: relative;
}

.filterButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    font-size: .875rem;
    line-height: 1.125rem;
    border-radius: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;
    width: 100%;
    position: relative;
    z-index: 4;
    color: var(--color-black);
    letter-spacing: 0;
    text-transform: capitalize;
    margin: 0;
    transition: background-color 0.3s ease-in-out;

    &.active {
        background-color: #fff;
        border-color: #E2E2E2;
        border-bottom-color: #fff;
        border-radius: 1.5rem 1.5rem 0 0;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: -2px;
            background-color: #fff;
        }
    }

    &:hover {
        cursor: pointer;
    }
}  

.caret {
    margin-left: 10px;
    position: relative;
}

.filterOptions {
    position: absolute;
    left: 0;
    max-width: 46.5rem;
    min-width: 7.5rem;
    max-height: 18rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 32px 0 #0000001a;
    background-color: #fff;
    border: 1px solid #E2E2E2;
    z-index: 3;
    padding: 1rem 1rem 1.5rem 1rem;
    gap: 1rem;
    overflow: scroll;
    // flex-wrap: wrap;
    // min-width: 230px;
}

.filterOption {
    padding: 0;
    margin: 0;
    font-size: .875rem;
    font-family: $font-regular;
    color: var(--color-black);
    background: transparent;
    border: none;
    position: relative;
    padding-left: 35px;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        border: 1px solid #9a9a9a;
        background-color: white;
        border-radius: 2px;
    }

    &.active:before {
        background-color: var(--color-black);
        border-color: var(--color-black);
    }

    &.active::after {
        content: '';
        position: absolute;
        left: 0;
        top: -6px;
        height: 1.5rem;
        width: 1.5rem;
        background-color: white;
        mask: url('../../../assets/svg/check-alt.svg') left center / contain no-repeat;
    }
}

.activeFilters {
    > div {
        margin: 1rem 0 0 0;
        gap: .5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.activeFilter {
    background-color: white;
    border: 1px solid #EEEEEE;
    font-size: .75rem;
    line-height: 1rem;
    border-radius: 1.5rem;
    padding: .3rem .5rem .25rem;
    font-family: $font-regular;
    color: var(--color-black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s ease-in-out;

    button {
        padding: 0;
        margin: 0 0 0 0.5rem;
        z-index: 1;
        position: relative;
        top: -2px;
        background-color: var(--color-black);
        height: 1rem;
        width: 1rem;
        mask: url('../../../assets/svg/close.svg') left center / contain no-repeat;

        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        background-color: #E1E1E1;
    }
}

.sort {
    display: flex;
}

.clearAll {
    background-color: transparent;
    color: var(--color-black);
    padding: 0;
    border: none;
    margin-left: auto;
    margin-top: -20px;
    display: flex;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    text-transform: none;
    font-family: $font-regular;
    font-size: .875rem;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
}

.mobileFilters {
    display: none;
}

@media (max-width: $media-desktop) {
    .desktopFilters {
        display: none;
    }

    .wrapper {
        margin-bottom: 20px;
    }

    .clearAll {
        margin: 0.5rem 1rem 0 auto;
    }
      
    .mobileFilters {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;

        &.active {
            .filterPanel {
                transform: translateX(0);
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9998;
        transition: opacity 0.3s ease;
    }

    .sort {
        display: none;
    }

    .activeFilters {
        overflow: hidden;
        padding: 0;
        white-space: nowrap;

        > div {
            padding: 0 1rem;
        }
    }

    .filterButton {
        padding: 1.25rem 1.75rem;

        .caret {
            position: relative;
            top: -2px;
            background-color: var(--color-black);
            height: 1.5rem;
            width: 1.5rem;
            mask: url('../../../assets/svg/filter.svg') left center / contain no-repeat;
        }
    }

    .filterPanel {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: fixed;
        display: flex;
        align-items: center;
        z-index: 9999;
        flex-direction: column;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        height: 100%;
        overflow-y: auto;
        background-color: white;
        width: 65%;

        .filterPanelContainer {
            height: 100%;
            width: 100%;
            overflow-x: hidden;
            padding-bottom: 115px;
        }

        .filter {
            width: 100%;
            height: auto;
            border-bottom: 1px solid #E1E1E1;
        }

        .filterPanelHeader {
            padding: 1rem;
            border-bottom: 1px solid #E1E1E1;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 1.25rem;
                line-height: 1.5rem;
                font-family: $font-medium;
            }
        }

        .filterPanelFooter {
            gap: 1rem;
            bottom: 0;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            box-shadow: 0 -4px 10px 0 #00000014;
            width: 100%;
            background-color: white;
            box-sizing: border-box;
            position: absolute;
            z-index: 9;

            .clearAll {
                justify-content: center;
                margin: 0;
            }
        }

        .filterPanelCta {
            align-self: center;
            border-radius: 30px;
            background-color: var(--color-black);
            font-size: 1.125rem;
            line-height: 1.5rem;
            color: white;
            font-family: $font-medium;
            text-decoration: none;
            width: 100%;
            outline: none;
            padding: .9rem 1.5rem .6rem;
            display: inline-block;
            text-align: center;
            border: none;
        }

        .filterPanelClose {
            background-color: var(--color-black);
            height: 1.5rem;
            width: 1.5rem;
            mask: url('../../../assets/svg/close.svg') left center / contain no-repeat;
        }

        .filterButton {
            padding: .25rem 1rem;
            border: none;
            font-size: 1rem;
            border-radius: 0;
            background-color: transparent;
            text-transform: none;

            &.active {
                .caret {
                    transform: rotate(180deg);
                    top: 0;
                }
            }

            .caret {
                background-color: transparent;
                mask: none;
                top: 2px;
                transition: transform .3s ease-in-out;
            }

            &:after {
                display: none;
            }
        }

        .filterOptions {
            padding: .5rem 1rem 1.5rem 1rem;
            position: static;
            box-shadow: none;
            border: none;
            flex-direction: column;
            gap: 1.5rem;
        }

        .filterOption {
            text-align: left;
            width: 100%;
            height: 23px;

            &:before {
                top: 9px;
                left: -2px;
            }

            &:after {
                left: -2px;
                top: -3px;
            }
        }

        .filterOptionSort {
            font-size: 1rem;
            line-height: 1.25rem;
            white-space: pre-wrap;

            &:before {
                border-radius: 100%;
                border-color: #9a9a9a;
                background: none;
            }

            &.active:after {
                mask: none;
                border-radius: 100%;
                top: 1px;
                width: 1rem;
                height: 1rem;
                left: 1px;
                border: 1px solid white;
                background: black;
            }
        }
        
        .activeFilters {
            width: 100%;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid #E1E1E1;
            box-sizing: border-box;
            
            > div {
                align-items: flex-start;
                overflow: hidden;
                padding: 0;
                margin: 0;
            }
        }

        .clearAll {
            padding: 0;
            margin: 0.5rem 0 0 auto;
            display: flex;
        }
    }
}