@import '../../variables.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    text-align: center;
    color: var(--color-black);
    margin-top: 35px;
}

.title {
    color: var(--color-black);
    text-transform: none;
    font-family: $font-medium;
    letter-spacing: 0;
    margin-bottom: 22px;
    max-width: 64%;
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 500;
}

.text {
    font-family: $font-regular;
    letter-spacing: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    display: flex;
    margin: 0 23%;
}

@media (max-width: $media-desktop) {
    .container {
        margin-top: 15px;
    }

    .title {
        margin-bottom: 15px;
        max-width: none;
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .text {
        margin: 0;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}