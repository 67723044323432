@import '../../variables.scss';

.priceContainer {
    margin: 1rem 0;
}

.priceSale,
.priceCrossed,
.price {
    font-size: .875rem;
    line-height: 1rem;
    font-family: $font-medium;
}

.priceCrossed {
    text-decoration: line-through;
}

.priceSale {
    color: var(--color-red);
    margin-left: 8px;
}

.priceSaleFull {
    color: var(--color-black);
    margin-left: 0;
}