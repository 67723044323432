@import '../../variables.scss';

.container {
    padding: 0 10px;
    flex: 0 0 65%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gallery-items-gap);
}

.thumb {
    flex: 0 0 calc(50% - (var(--gallery-items-gap) / 2));
    position: relative;
    overflow: hidden;
    cursor: zoom-in;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    video {
        height: 100%;
        width: 100%;
        max-width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
    }
    .galleryCta{
        position: absolute;
        bottom: 0;
        right:0;
        background-color: rgba(255,255,255, .9);
        height: 35px;
        width: 35px;
        padding:0;
        color: black;
        border-radius: 0;
        margin: 0;
        font-family: 'icomoon' !important;
        font-size: 18px;
        color: var(--color-black);
        &:after{
            content: "";
        }
    }
    .zoom {
        opacity: 0;
        transition: opacity 450ms;
        position: absolute;
        background-position: attr(x) attr(y);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}

.desktop {
    display: flex;
}

.mobile {
    display: none;
}

.loaderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: $media-tablet) {
    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }

    .container {
        flex-wrap: nowrap;
        overflow-x: scroll;
        justify-content: flex-start;
        padding: 0 0 0 5px;
        margin-bottom: 20px;
        align-self: stretch;
        gap: calc(var(--gallery-items-gap) * 0.6);
        min-height: 250px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .thumb {
        flex: 0 0 calc(100vw - 10px);

        .zoom {
            display: none;
        }
    }

}